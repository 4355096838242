import { useAuth } from './hooks';
import LoadingElement from './components/loading';
import { SubjectReputation } from './components/subject';


function App() {
  const { data, isLoading, error } = useAuth();

  const contactId = new URLSearchParams(window.location.search).get('contactId');
  const locationId = new URLSearchParams(window.location.search).get('locationId');

  if (isLoading) return <LoadingElement />;
  if (error || !data) return <div> Error: {error?.stack}</div>;
  const content = contactId && locationId ? <SubjectReputation contactId={contactId} locationId={ locationId } /> : <>
    <h3> SSO Data: </h3>
    <p> Name: {data.userName} </p>
    <p> Email: {data.email} </p>
    <p> Role: {data.role} </p>
    <p> UserId: {data.userId} </p>
    <p> CompanyId: {data.companyId} </p>
  </>;
  return (
    <div className="App">
      { content }
    </div>
  );
}

export default App;
