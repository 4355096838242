import { Box, CircularProgress } from '@mui/material';
import { Grid } from '@mui/system';

const LoadingElement = () => {
  return (
    <Grid container
      alignItems='center'
      width='100%'
      padding={ 1 }
    >
      <Box flexGrow={ 1 } />
      <CircularProgress />
      <Box flexGrow={ 1 } />
    </Grid>
  );
};

export default LoadingElement;
