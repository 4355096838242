import { useEffect, useState } from 'react'
import { useQuery } from 'react-query';

const {
  REACT_APP_API_SERVER: apiServer = 'http://localhost:3400',
} = process.env;

export interface AuthData {
  userId: string;
  companyId: string;
  userName: string;
  email: string;
  role: 'admin' | string;
  type: 'agency' | 'subaccount';
  permissions?: Record<string,boolean>;
}

export interface AuthResponse {
  session: AuthData;
  token: string;
}

export interface AuthStatus {
  data?: AuthData;
  session?: string;
  isError: Boolean;
  isLoading: Boolean;
  error?: Error;
  token?: string;
}
export const useAuth = (): AuthStatus => {
  const sessionStorageKey = 'highlevel_session';
  const [ session, setSession ] = useState( '' );

  useEffect( () => {
    try {
      const storedSession = localStorage.getItem( sessionStorageKey );
      if( storedSession ) setSession( storedSession );
    } catch {}
    window.addEventListener( 'message', ( { data } ) => {
      console.log( data );
      if( data.message === 'REQUEST_USER_DATA_RESPONSE' ) {
        console.log( 2 );
        setSession( data.payload );
        localStorage.setItem( sessionStorageKey, data.payload );
      }
    } );
    window.parent.postMessage( { message: 'REQUEST_USER_DATA' }, '*' );
  }, [] );

  const authQuery = useQuery<AuthResponse>( {
    queryKey: [ 'auth', session ],
    enabled: !!session,
    queryFn: async () => {
      const res = await fetch(`${apiServer}/auth/highlevel/sso`, {
        headers: { 'x-sso-session': session },
      });
      return await res.json();
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  } )

  return {
    data: authQuery.data?.session,
    error: authQuery.error as Error,
    isError: authQuery.isError,
    isLoading: authQuery.isLoading,
    session,
    token: authQuery.data?.token,
  };
}
