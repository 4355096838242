import { LoadingButton } from '@mui/lab';
import { ChevronLeft, ChevronRight, Close, Settings } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, FormControlLabel, FormGroup, Grid2 as Grid, IconButton, Modal, Paper, SxProps, TextField, Tooltip, Typography } from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../hooks/auth';
import { ProfileData, ProfileReputation, ProfilesResponse, ProfileSummary } from './profile';
import { useQuery } from 'react-query';
import LoadingElement from './loading';

const {
  REACT_APP_API_SERVER: apiServer = 'http://localhost:3400',
} = process.env;

export interface SubjectAttributes {
  name: string;
  reference: string; // contactId
  organization: string; // 'GoHighLevel'
  kind: 'Location' | 'Practitioner';
  createdAt: Date;
  updatedAt: Date;
}

export interface SubjectRelationships {
  profiles: {
    data: Array<{
      type: 'profiles';
      id: string;
    }>
  }
}

export interface SubjectData {
  id: string;
  attributes: SubjectAttributes;
  relationships: SubjectRelationships;
}

export interface SubjectResponse {
  data: SubjectData;
}

export interface SubjectsResponse {
  data: Array<SubjectData>
}

interface GooglePlace {
  id: string,
  displayName: { text: string },
  formattedAddress: string,
  googleMapsUri: string,
}

export interface SubjectReputationProps {
  contactId: string;
  locationId: string;
}
export const SubjectReputation: FC<SubjectReputationProps> = props => {
  const { contactId, locationId } = props;
  const organization = 'GoHighLevel';
  const { token } = useAuth();
  const [ isManageOpen, setIsManageOpen ] = useState<boolean>(false);
  const [ isCreating, setIsCreating ] = useState<boolean>(false);
  const [ displayedProfile, setDisplayedProfile ] = useState( 0 );

  const contactQuery = useQuery( {
    queryKey: [ 'businessId', { locationId }, { contactId } ],
    queryFn: async () => {
      if (!token) return;
      const query = new URLSearchParams( { locationId } );
      const response = await fetch( `${apiServer}/api/v1/highlevel/contacts/${contactId}?${ query }`, {
        headers: { Authorization: `Bearer ${token}` },
      } );
      return await response.json();
    },
    enabled: !!token,
    staleTime: 1000 * 60 * 5, // 5 minutes
  } );

  const reference = useMemo( () => {
    if( !contactQuery.isFetched ) return;
    if( contactQuery.isError ) return;
    return contactQuery.data?.businessId;
  }, [ contactQuery ] );

  const subjectQuery = useQuery( {
    queryKey: [ 'subject', { reference, organization } ],
    queryFn: async () => {
      if (!token || !reference) return;
      const query = new URLSearchParams( {
        'filter[reference]': reference,
        'filter[organization]': organization,
      } );
      const response = await fetch(`${apiServer}/api/v1/subjects?${query}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const subjects: SubjectsResponse = await response.json();
      return subjects.data.length ? subjects.data[ 0 ] : undefined;
    },
    enabled: !!token && !!reference,
    staleTime: 1000 * 60 * 5, // 5 minutes
  } );

  // create subject if it doesn't exist
  useEffect( () => {
    ( async () => {
      if( !subjectQuery.isFetched || subjectQuery.data || isCreating ) return;
      setIsCreating( true );
      await fetch( `${apiServer}/api/v1/subjects`, {
        headers: {
          Authorization: `Bearer ${ token }`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify( {
          data: {
            type: 'subjects',
            attributes: {
              reference,
              organization,
              kind: 'Location',
            }
          }
        } )
      } );
      await subjectQuery.refetch();
      setIsCreating( false );
    } )();
  }, [ isCreating, reference, subjectQuery, token ] );

  const profiles = useMemo( () => subjectQuery.data?.relationships.profiles.data || [], [ subjectQuery ] );

  // auto-open Manage dialog if no profiles exist
  useEffect( () => {
    if( subjectQuery.isFetched && subjectQuery.data && !profiles.length ) {
      setIsManageOpen( true );
    }
  }, [ profiles, subjectQuery ] );

  if ( contactQuery.isLoading || subjectQuery.isLoading || isCreating ) return <LoadingElement />;
  if ( !reference ) return <p> No business found for this contact. </p>;
  if ( !subjectQuery.data ) return <p> No data found for businessId: {reference} </p>;
  if ( isManageOpen || !profiles.length ) return <>
    <Grid container justifyContent='end' direction='row'>
      <IconButton
        onClick={() => setIsManageOpen(false)}
        sx={{ display: profiles.length ? 'block' : 'none' }}
      ><Close /></IconButton>
    </Grid>
    <ManageSubject subjectId={ subjectQuery.data.id } onChange={ () => subjectQuery.refetch() } />
  </>;
  return <>
    <div
      style={{
        paddingBottom: '0.5rem',
        minHeight: '98vh',
      }}
    >
      <Grid container justifyContent='end' direction='row'>
        <Tooltip title='Manage links'>
          <IconButton onClick={() => setIsManageOpen(true)} ><Settings /></IconButton>
        </Tooltip>
      </Grid>
      <Grid container direction='row' alignItems='center'>
        <IconButton
          onClick={() => setDisplayedProfile( current => current - 1 ) }
          sx={{
            display: displayedProfile === 0 ? 'none' : 'block',
            position: 'absolute',
            left: '0.6em',
            zIndex: 1,
          }}
        ><ChevronLeft /></IconButton>
        <Paper
          elevation={2}
          style={{
            padding: '1rem',
            margin: '1rem',
            flexGrow: 1
          }}
        >
          <ProfileReputation profileId={ profiles[ displayedProfile ].id } />
        </Paper>
        <IconButton
          onClick={() => setDisplayedProfile( current => current + 1 ) }
          sx={{
            display: displayedProfile === profiles.length - 1 ? 'none' : 'block',
            position: 'absolute',
            right: '0.6em',
          }}
        ><ChevronRight /></IconButton>
      </Grid>
      <ShareSubjectModal subject={ subjectQuery.data } sx={{ marginX: 1 }} />
    </div>
  </>;
};

export interface ManageSubjectProps {
  subjectId: string;
  onChange?: () => unknown;
}
export const ManageSubject: FC<ManageSubjectProps> = props => {
  const { subjectId, onChange } = props;
  const { token } = useAuth();
  const [existingProfiles, setExistingProfiles] = useState<ProfileData[]>([]);
  const [query, setQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [candidates, setCandidates] = useState<GooglePlace[]>([]);
  const [selections, setSelections] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ severity: 'success'|'error', text: string }>();

  const subjectQuery = useQuery( {
    queryKey: [ 'subject', { id: subjectId } ],
    queryFn: async () => {
      const response = await fetch(`${apiServer}/api/v1/subjects/${subjectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const responseJson: SubjectResponse = await response.json();
      return responseJson.data;
    },
    enabled: !!token,
    staleTime: 1000 * 60 * 5, // 5 minutes
  } );
  const subject = useMemo(() => subjectQuery.data, [subjectQuery]);

  useEffect(() => {
    (async () => {
      if (!subject || !token) return;
      const profileIds = subject.relationships.profiles.data.map(p => p.id);
      if (!profileIds.length) return;
      const response = await fetch(`${apiServer}/api/v1/profiles?filter[id]=${profileIds.join(',')}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const responseJson: ProfilesResponse = await response.json();
      setExistingProfiles(responseJson.data);
    })();
  }, [subject, token]);

  const fetchPlaces = useCallback(async () => {
    if (!token) return;
    setAlert(undefined);
    setIsLoading(true);
    const response = await fetch(`${apiServer}/api/v1/profiles/search-places`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ textQuery: query }),
    });
    setCandidates(await response.json());
    setIsLoading(false);
  }, [token, query]);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(e => setQuery(e.target.value), [setQuery]);

  const updateSubject = useCallback(async () => {
    if (!token) return;
    if (!selections.length) return;
    if (!subject) return;

    setAlert(undefined);
    setIsUpdating(true);
    const profiles = await Promise.all(selections.map(async selection => {
      const place = candidates.find(c => c.id === selection);
      if (!place) return; // should never happen
      const query = new URLSearchParams({ url: place.googleMapsUri });
      try {
        const profile = await fetch(`${apiServer}/api/v1/profiles/lookup?${query}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return profile.json();
      } catch { return; }
    }));
    const profileIds: string[] = profiles.filter(p => p).map(p => p.id);

    const profileRelationships = (subject.relationships.profiles.data || []).concat(profileIds.map(id => ({ type: 'profiles', id })));
    const updatedSubject = {
      data: {
        ...subject,
        relationships: {
          ...subject.relationships,
          profiles: {
            data: uniqBy(profileRelationships, 'id'),
          }
        }
      }
    };
    try {
      await fetch(`${apiServer}/api/v1/subjects/${subject.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify(updatedSubject),
      });
      setAlert( { severity: 'success', text: 'Profiles added successfully.' } );
      setCandidates([]);
      setSelections([]);
      subjectQuery.refetch();
      onChange?.();
    } catch (e) {
      console.error(e);
      setAlert( { severity: 'error', text: 'Something went wrong.' } );
    }
    setIsUpdating(false);

  }, [candidates, onChange, selections, subject, subjectQuery, token]);

  return <>
    <Grid
      container
      direction='column'
      alignItems='start'
      padding={1}
      spacing={1}
      margin={1}
    >
      <Typography variant='body1' sx={{ textTransform: 'uppercase' }} color='primary'>Linked Profiles</Typography>
      {existingProfiles.length ? existingProfiles.map(p => (
        <ProfileSummary key={p.id} profileId={p.id} />
      )) : <Typography variant='body1'> None </Typography>}
      <br />
      <Typography variant='body1' sx={{ textTransform: 'uppercase' }} color='primary'>Add Profiles</Typography>
      <TextField
        name='query'
        placeholder='Enter a company name and/or address.'
        multiline
        value={query}
        onInput={handleInputChange}
        style={{ width: '80%' }}
      />
      <LoadingButton
        onClick={fetchPlaces}
        loading={isLoading}
        disabled={!query.length}
        variant='outlined'
      > Search </LoadingButton>
      {candidates.length ? <FormGroup>
        {candidates.map(c => (
          <FormControlLabel
            key={c.id}
            label={
              <Typography marginBottom={ 1 } color={ !!existingProfiles.find( p => p.attributes.url === c.googleMapsUri ) ? 'textDisabled' : 'textPrimary' }>
                <Typography>{c.displayName.text}</Typography>
                <Typography variant='caption'>{c.formattedAddress}</Typography>
              </Typography>
            }
            control={
              <Checkbox
                onChange={e => e.target.value ? setSelections(v => [...v, c.id]) : setSelections(v => v.filter(vv => vv !== c.id))}
                disabled={ !!existingProfiles.find( p => p.attributes.url === c.googleMapsUri ) }
                disableRipple
                disableFocusRipple
                disableTouchRipple
                sx={{ paddingTop: 0 }}
              />
            }
            sx={{ alignItems: 'flex-start' }}
          />
        ))}
      </FormGroup> : null}
      {candidates.length ? (
        <LoadingButton
          onClick={updateSubject}
          disabled={!selections.length}
          loading={isUpdating}
          variant='outlined'
        > Add selected </LoadingButton>
      ) : null}
      { alert ? <Alert severity={alert.severity}>{ alert.text }</Alert> : null }
    </Grid>
  </>;
}

interface ReportRequestData {
  kind: 'profile' | 'subject';
  id: string;
  recipients: never[]; // normally required, but overwritten by highLevelOptions.contactId
  bodyText?: string;
  highLevelOptions: {
    contactId: string;
    emailFrom: string;
  }
}
export interface ShareSubjectModalProps {
  subject: SubjectData;
  sx?: SxProps;
}
export const ShareSubjectModal: FC<ShareSubjectModalProps> = props => {
  const { subject } = props;
  const { token, data } = useAuth();
  const [ open, setOpen ] = useState( false );
  const [ bodyText, setBodyText ] = useState( '' );
  const [ loading, setLoading ] = useState( false );
  const [ error, setError ] = useState( '' );

  const contactId = useMemo( () => new URLSearchParams(window.location.search).get('contactId'), [] );

  const sendReport = useCallback( async () => {
    if( !token || !contactId || !data?.email ) {
      setError( 'Unable to send report.' );
      return;
    }
    const isProfileReport = subject.relationships.profiles.data.length === 1; // only one profile linked
    const body: ReportRequestData = {
      kind: isProfileReport ? 'profile' : 'subject',
      id: isProfileReport ? subject.relationships.profiles.data[ 0 ].id : subject.id,
      recipients: [],
      bodyText,
      highLevelOptions: {
        contactId: contactId,
        emailFrom: data?.email,
      }
    };
    setLoading( true );
    const response = await fetch( `${apiServer}/api/v1/reports`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${ token }`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( body ),
    } );
    setLoading( false );
    if ( response.ok ) {
      setOpen( false );
    }
    setError( 'Unable to send report.' );
  }, [ bodyText, contactId, data, subject, token ] );

  const handleOpen = () => {
    setError( '' );
    setOpen( true );
  };

  return <>
    <Button
      variant='outlined'
      onClick={ handleOpen }
      disabled={ !subject.relationships.profiles.data.length }
      sx={ props.sx }
    > Share </Button>
    <Modal
      open={ open }
      onClose={ () => setOpen( false ) }
    >
      <Grid container
        direction='column'
        alignItems='start'
        spacing={ 1 }
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '85%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '4px',
          padding: '16px',
        }}
      >
        <Box sx={{ position: 'absolute', right: 2, top: 2 }}>
          <IconButton
            onClick={() => setOpen(false)}
          ><Close /></IconButton>
        </Box>
        <Typography variant='h6'> Share Profile Report </Typography>
        <Typography>
          <Typography variant='body1'>{ subject.attributes.name }</Typography>
        </Typography>
        <TextField
          name='bodyText'
          required
          placeholder={ `Here's your latest Reputation Report for ${ subject.attributes.name }.` }
          value={ bodyText }
          onInput={ ( e: React.ChangeEvent<HTMLInputElement> ) => setBodyText( e.target.value ) }
          multiline
          minRows={ 3 }
          maxRows={ 12 }
          fullWidth
        />
        <LoadingButton
          variant='contained'
          onClick={ sendReport }
          loading={ loading }
          disabled={ !bodyText }
        > Send </LoadingButton>
        { error ? <Alert severity='error'>{ error }</Alert> : null }
      </Grid>
    </Modal>
  </>;
};
