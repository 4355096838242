import { Box, Typography } from '@mui/material';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../hooks';

const {
  REACT_APP_API_SERVER: apiServer = 'http://localhost:3400',
} = process.env;

export type DurationType = 'months' | 'years';
export interface ChartProps {
  profileId: string;
  duration: {
    type: DurationType;
    count: number;
  };
  height?: number;
  maxWidth?: number;
  refreshMillis?: number;
  title?: string;
}
export const Chart: FC<ChartProps> = props => {
  const {
    profileId,
    duration,
    height = 500,
    refreshMillis,
    title,
  } = props;

  const { token } = useAuth();

  const query = useMemo( () => {
    return new URLSearchParams( {
      durationCount: `${duration.count}`,
      durationType: duration.type,
    } ).toString();
  }, [ duration.count, duration.type ] );

  const storageKey = useMemo( () => `ProfileChart_${profileId}_${duration.type}_${duration.count}`, [ duration.count, duration.type, profileId ] );

  const storedData = useMemo( () => {
    const data = localStorage.getItem( storageKey );
    return data ? JSON.parse( data ) : undefined;
  }, [ storageKey ] );

  const chartQuery = useQuery<BarDatum[]>( {
    queryKey: [ 'ProfileChart', { profileId, duration } ],
    queryFn: async () => {
      if( !token ) return [];
      const response = await fetch( `${apiServer}/api/v1/profiles/${profileId}/trends?${query}`, {
        headers: { Authorization: `Bearer ${ token }` },
      } );
      if( response.status >= 500 ) throw new Error();
      const data = await response.json();
      try {
        localStorage.setItem( storageKey, JSON.stringify( data ) );
      }
      catch { /* ignore errors setting local storage */ }
      return data;
    },
    enabled: !!token,
    placeholderData: storedData,
    retry: 3,
    refetchInterval: refreshMillis,
    staleTime: 1000 * 60 * 5, // 5 minutes
  } );

  const hasAngledLabels = useMemo( () => duration.count > 6 , [ duration.count ] );

  if( chartQuery.isLoading ) return null;
  if( !chartQuery.data?.length ) return (
    <Box height={ height }>
      <Typography
        variant='body1'
        margin='10px'
      >Unable to load chart data.</Typography>
    </Box>
  );
  return (
    <Box
      height={ height }
    >
      <ResponsiveBar
        data={ chartQuery.data }
        indexBy='id'
        keys={ [ '1 star', '2 stars', '3 stars', '4 stars', '5 stars' ] }

        margin={ { top: 50, right: 20, bottom: hasAngledLabels ? 75 : 60, left: 65 } }

        theme={ {
          // background: theme.palette?.background?.default,
          axis: {
            legend: { text: { fontSize: 18 } },
            ticks: { text: { fontSize: 14 } },
          },
          labels: { text: { fill: '#333' } },
          legends: { text: { fontSize: 14 } },
          // textColor: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
          // tooltip: {
          //   container: {
          //     backgroundColor: theme.palette?.background?.default,
          //     color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
          //   },
          // },
        } }

        groupMode='stacked'
        padding={ 0.25 }

        colors={ { scheme: 'red_yellow_green', size: 5 } }

        enableLabel={ false }

        axisLeft={ { legend: 'New Ratings Count', tickSize: 5, tickPadding: 5, tickValues: 5, legendOffset: -45, legendPosition: 'middle' } }
        axisBottom={ {
          legend: title,
          legendPosition: 'middle',
          legendOffset: hasAngledLabels ? 55 : 40,
          tickRotation: hasAngledLabels ? -33 : 0,
          tickSize: 5,
        } }

        legends={ [ {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateY: -40,
          itemWidth: 80,
          itemHeight: 20,
          itemsSpacing: 0,
          dataFrom: 'keys',
        } ] }
      />
    </Box>
  );
}
