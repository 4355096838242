import { Launch } from '@mui/icons-material';
import { capitalize, Stack, Tab, Tabs, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../hooks';
import { Chart } from './chart';
import LoadingElement from './loading';

const {
  REACT_APP_API_SERVER: apiServer = 'http://localhost:3400',
} = process.env;

export interface ProfileReputationParams {
  profileId: string;
}
export const ProfileReputation: FC<ProfileReputationParams> = params => {
  const { profileId } = params;
  const { token } = useAuth();
  const [chartTab, setChartTab] = useState(0);

  const profileQuery = useQuery<ProfileData>( {
    queryKey: [ 'profile', profileId ],
    queryFn: async () => {
      const response = await fetch( `${apiServer}/api/v1/profiles/${ profileId }`, {
        headers: { Authorization: `Bearer ${ token }` },
      } );
      const responseJson: ProfileResponse = await response.json();
      return responseJson.data;
    },
    enabled: !!profileId && !!token,
    staleTime: 1000 * 60 * 5, // 5 minutes
  } );

  const summaryQuery = useQuery<SummaryAttributes>( {
    queryKey: [ 'summary', { profileId } ],
    queryFn: async () => {
      const searchParams = new URLSearchParams( {
        'filter[profile]': profileId,
        'sort': '-createdAt',
        'page[number]': '1',
        'page[size]': '1',
      } );
      const response = await fetch( `${apiServer}/api/v1/summaries?${ searchParams }`, {
        headers: { Authorization: `Bearer ${ token }` },
      } );
      const responseJson: SummariesResponse = await response.json();
      return responseJson.data[0]?.attributes;
    },
    enabled: !!profileId && !!token,
    staleTime: 1000 * 60 * 5, // 5 minutes
  } );

  if ( profileQuery.isLoading || summaryQuery.isLoading ) return <LoadingElement />;
  if ( !profileQuery.data || !summaryQuery.data ) return <p> No data found for profile. </p>;
  return <>
    <Stack>
      <Typography sx={{ fontWeight: 'bold' }}> {summaryQuery.data.name} </Typography>
      <Typography> {summaryQuery.data.address} </Typography>
      <Typography> {summaryQuery.data.phone} </Typography>
      <Typography>
        <a href={profileQuery.data.attributes.referralUrl.replace(/\/writereview/, '/reviews')} target='_blank' rel='noreferrer'>
          {capitalize(profileQuery.data.relationships.platform.data.id)}
          <Launch fontSize='inherit' sx={{ verticalAlign: 'text-bottom' }} />
        </a> average: <Typography component='span' sx={{ fontWeight: 'bold' }}>
          {summaryQuery.data.rating}
        </Typography> count: <Typography component='span' sx={{ fontWeight: 'bold' }}>
          {summaryQuery.data.count}
        </Typography>
      </Typography>
    </Stack>
    <Tabs value={chartTab} onChange={(_event, newValue: number) => setChartTab(newValue)}>
      <Tab label='Monthly' />
      <Tab label='Yearly' />
    </Tabs>
    {chartTab === 0 ? <Chart profileId={profileId} duration={{ type: 'months', count: 6 }} title='' /> : null}
    {chartTab === 1 ? <Chart profileId={profileId} duration={{ type: 'years', count: 5 }} title='' /> : null}
  </>;
}

export const ProfileSummary: FC<ProfileReputationParams> = params => {
  const profileId = params.profileId;
  const { token } = useAuth();

  const profileQuery = useQuery<ProfileData>( {
    queryKey: [ 'profile', profileId ],
    queryFn: async () => {
      const response = await fetch( `${apiServer}/api/v1/profiles/${ profileId }`, {
        headers: { Authorization: `Bearer ${ token }` },
      } );
      const responseJson: ProfileResponse = await response.json();
      return responseJson.data;
    },
    enabled: !!profileId && !!token,
    staleTime: 1000 * 60 * 5, // 5 minutes
  } );

  const summaryQuery = useQuery<SummaryAttributes>( {
    queryKey: [ 'summary', profileId ],
    queryFn: async () => {
      const searchParams = new URLSearchParams( {
        'filter[profile]': profileId,
        'sort': '-createdAt',
        'page[number]': '1',
        'page[size]': '1',
      } );
      const response = await fetch( `${apiServer}/api/v1/summaries?${ searchParams }`, {
        headers: { Authorization: `Bearer ${ token }` },
      } );
      const responseJson: SummariesResponse = await response.json();
      return responseJson.data[0]?.attributes;
    },
    enabled: !!profileId && !!token,
    staleTime: 1000 * 60 * 5, // 5 minutes
  } );

  return (
    <Typography>
      <Typography variant='body1'>{ summaryQuery.isLoading ? null : summaryQuery.data?.name }</Typography>
      <Typography variant='caption'>{ summaryQuery.isLoading ? null : summaryQuery.data?.address }</Typography> <br/>
      <Typography variant='caption'>{ profileQuery.isLoading ? null : profileQuery.data?.attributes.url }</Typography>
    </Typography>
  );
};

export interface ProfileAttributes {
  url: string,
  referralUrl: string,
  platform: string,
}

export interface ProfileRelationships {
  platform: {
    data: {
      type: 'platforms',
      id: string,
    }
  }
}

export interface ProfileData {
  id: string;
  attributes: ProfileAttributes;
  relationships: ProfileRelationships;
}

export interface ProfileResponse {
  data: ProfileData;
}

export interface ProfilesResponse {
  data: Array<ProfileData>;
}

export interface SummaryAttributes {
  rating: number,
  count: number,
  distribution: [number, number, number, number, number, number],
  name: string,
  address: string,
  phone: string,
  categories: string[],
}

export interface SummariesResponse {
  data: Array<{
    attributes: SummaryAttributes,
  }>;
}
